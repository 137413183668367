<template lang="html">
    <div>
          <v-menu open-on-hover offset-y bottom tile transition="slide-x-transition" v-for="(item, i) in navi" :key="i">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ma-1" color="white" v-bind="attrs" v-on="on" text tile router :to="makePath(item)">
                        {{item.titel}} <v-icon right dark v-if="item.subpages">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>

                <v-list flat dense  v-if="item.subpages">
                    <v-list-item-group >
                        <div v-for="(sub, j) in item.subpages" :key="j" >
                            <v-list-item class="tile" router :to="makePath(sub)">
                                <v-list-item-content>
                                    <v-list-item-title v-text="sub.titel"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                    </v-list-item-group>
                </v-list>
          </v-menu>
      </div>
</template>

<script>
import {
    makePath
} from '@/api/api'
export default {
    props: ['navi'],
    methods: {
        makePath
    },
}
</script>

<style>
.tile {
    border-bottom: 1px solid rgba(242, 244, 248, 0.7);
}

.tile * {
    text-transform: uppercase;
}

.tile:hover * {
    color: #c2a26a;
    transition: color .5s;
}
</style>
